export enum CustomerIndexIdentifierType {
    AUDIID = 'AUDIID',
    FSID = 'FSID',
    LIVECONTRACTID = 'LIVECONTRACTID',
    SEATID = 'SEATID',
    SKODAID = 'SKODAID',
    VERIMIID = 'VERIMIID',
    VWID = 'VWID',
    CARPRO_USER = 'CARPRO_USER',
    PAYBYPHONEID = 'PAYBYPHONEID',
    AUTOABO_TRANSACTION = 'AUTOABO_TRANSACTION',
    AUTOABO_PAYMENTSERIES = 'AUTOABO_PAYMENTSERIES',
    CARPRO_RESERVATION = 'CARPRO_RESERVATION',
    AOD_RENT_RESERVATION = 'AOD_RENT_RESERVATION',
    AUTOABO_RESERVATION = 'AUTOABO_RESERVATION',
    SALES_CONTRACT = '4SALES_CONTRACT',
    AUTOABO_MERCHANTKEY = 'AUTOABO_MERCHANTKEY',
    EMAIL = 'EMAIL',
    EMAIL_AUDIID = 'EMAIL_AUDIID',
    EMAIL_FSID = 'EMAIL_FSID',
    EMAIL_VWID = 'EMAIL_VWID',
    EMAIL_SKODAID = 'EMAIL_SKODAID',
    EMAIL_SEATID = 'EMAIL_SEATID',
    EMAIL_VERIMIID = 'EMAIL_VERIMIID',
    RAC_RESERVATION = 'RAC_RESERVATION',
    MARKET_USER_ES = 'MARKET_USER_ES',
    MARKET_USER_NL = 'MARKET_USER_NL',
    MARKET_USER_NLD = 'MARKET_USER_NLD',
    MARKET_USER_JPN = 'MARKET_USER_JPN',
    MARKET_USER_FR = 'MARKET_USER_FR',
    MARKET_USER_IT = 'MARKET_USER_IT',
    MARKET_USER_PT = 'MARKET_USER_PT',
    MARKET_USER_UK = 'MARKET_USER_UK',
    MARKET_USER_IE = 'MARKET_USER_IE',
    MARKET_USER_SK = 'MARKET_USER_SK',
    MARKET_USER_CZ = 'MARKET_USER_CZ',
    MARKET_USER_MX = 'MARKET_USER_MX',
}

export type CustomerIndexItem = {
    type: CustomerIndexIdentifierType;
    id: string;
    createdAt?: string;
    expiredAt?: string;
    additionalInformation?: string;
};

export type SendCustomerIndexRequest = CustomerIndexItem[];

export type SendCustomerIndexResponse = {
    id: string;
};

export type SearchCustomerIndexResponse = { id?: string; items: CustomerIndexItem[] } | null;

export type DeleteCustomerIndexItemRequest = {
    identifierTypeToDelete: CustomerIndexIdentifierType;
    externalUserId: string;
};
